<template>
  <a-group icon="event" class="px-2 py-2">
    <div class="d-flex flex-row align-center">
      <span>From</span>
      <year-picker class="ml-2" style="width: 105px" v-model="period.startYear" @input="updateStartDate" />
      <month-picker class="ml-2" style="width: 135px" v-model="period.startMonth" @input="updateStartDate" />
      <day-picker
        class="ml-2"
        style="width: 90px"
        :month="period.startMonth"
        v-model="period.startDay"
        @input="updateStartDate"
      />
      <span class="ml-2">To</span>
      <year-picker class="ml-2" style="width: 105px" v-model="period.endYear" @input="updateEndDate" />
      <month-picker class="ml-2" style="width: 135px" v-model="period.endMonth" @input="updateEndDate" />
      <day-picker
        class="ml-2"
        style="width: 90px"
        :month="period.endMonth"
        v-model="period.endDay"
        @input="updateEndDate"
      />
    </div>
  </a-group>
</template>

<script>
export default {
  props: {
    id: String,
    value: Object,
  },
  computed: {
    period: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    updateStartDate() {
      if (!this.id) {
        this.period.endYear = this.period.startYear; // Set end year to same as start year for editing quote
      }
      if (this.period.startMonth == null) {
        // Clear startDay if startMonth is null
        this.period.startDay = null;
      }
      this.$emit("input", this.period);
    },
    updateEndDate() {
      if (this.period.endMonth == null) {
        // Clear endDay if endMonth is null
        this.period.endDay = null;
      }
      this.$emit("input", this.period);
    },
  },
};
</script>
