<template>
  <v-col>
    <d-header :label="quoteId ? 'Edit Quote' : 'New Quote'" />
    <v-row justify="space-between" class="px-3">
      <v-row>
        <g-button class="mr-3" label="Cancel" @onclick="goback" />
        <g-button
          class="mr-3"
          v-if="quoteId"
          type="primary"
          label="Generate booking"
          @onclick="generateBooking"
          :disabled="isSubmitDisabled"
        />
        <g-button
          class="mr-3"
          v-if="quoteId"
          type="primary"
          label="Export Overview"
          @onclick="exportOverviewToExcel"
          :disabled="isSubmitDisabled"
        />
        <g-button
          class="mr-3"
          v-if="quoteId"
          type="primary"
          label="Export Detail"
          @onclick="exportDetailToExcel"
          :disabled="isSubmitDisabled"
        />
        <g-button
          class="mr-3"
          v-if="quoteId"
          type="primary"
          label="Overview Option"
          @onclick="overviewOptions"
          :disabled="isSubmitDisabled"
        />
      </v-row>
      <div>
        <v-row>
          <g-button
            v-if="quoteId"
            class="mr-3"
            label="Duplicate"
            :disabled="isSubmitDisabled"
            @onclick="duplicateQuote"
          />
          <delete-button v-if="quoteId" class="mr-3" :ondelete="deleteQuote" />
          <save-button v-if="quoteId" :disabled="isSubmitDisabled" :onsave="submitQuote" />
          <g-button v-else type="primary" label="Create" :disabled="isSubmitDisabled" @onclick="submitQuote" />
        </v-row>
      </div>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    quoteId: {
      type: String,
      default: null,
    },
    isSubmitDisabled: Boolean,
    onGenerateToBooking: Function,
    onExportOverviewToExcel: Function,
    onExportDetailToExcel: Function,
    onOverviewOptions: Function,
    onSubmit: Function,
    onDelete: Function,
    onDuplicate: Function,
  },
  methods: {
    goback() {
      this.$router.push({ name: "quotes" });
    },
    generateBooking() {
      return this.onGenerateToBooking();
    },
    exportOverviewToExcel() {
      return this.onExportOverviewToExcel();
    },
    exportDetailToExcel() {
      return this.onExportDetailToExcel();
    },
    overviewOptions() {
      return this.onOverviewOptions();
    },
    submitQuote() {
      return this.onSubmit();
    },
    deleteQuote() {
      return this.onDelete();
    },
    duplicateQuote() {
      return this.onDuplicate();
    },
  },
};
</script>
