<template>
  <div>
    <v-row class="mx-2" justify="space-between">
      <span class="subtitle-2">Suppliers to Email</span>

      <span class="caption"> Total selected: {{ Object.keys(value.contacts).length }} </span>
    </v-row>

    <div v-if="!supplierList.length">
      <span class="caption px-3">No Suppliers Assigned</span>
    </div>

    <v-data-table
      v-else
      disable-pagination
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="supplierList"
      :loading="loading"
      loading-text="Fetching suppliers"
      height="500px"
      show-select
      single-select
      class="database-table a-card nested-rows"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr class="database-row" :class="{ 'primary--text grey lighten-2': selectAll }" @click="toggleSelectAll">
            <td>
              <input type="checkbox" :checked="selectAll" />
            </td>
            <td>SELECT ALL</td>
          </tr>
          <SelectSupplierRow
            v-for="item in items"
            :key="item.id"
            :maptype="maptype"
            :supplier="item"
            :selectedContacts="value.contacts"
            :bookingSuppliers="bookingSuppliers"
            :bookingDays="bookingDays"
            :pax="bookingPax"
            :roomBreakdownData="roomBreakdownData"
            :compileType="value.groupBy[item.supplier_id] || 'GROUP'"
            @onclick="onclickSupplier"
            @setGroupping="setGroupping"
            @setEqualRooms="(v) => $emit('setEqualRooms', v)"
            :ignoreProducts="value.ignoreProducts"
            @updateIgnoreProduct="updateIgnoreProduct"
          >
            <template v-slot:default="slotProps">
              <ContactRow
                v-for="contact in slotProps.contacts"
                :key="contact.id"
                :contact="contact"
                :selectedContacts="value.contacts"
                @onclick="onclickContact"
              />
            </template>
          </SelectSupplierRow>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import BookingEmailService from "../../service";
import { BookingRoomService } from "@/modules/bookings.rooms";

import SelectSupplierRow from "../_SelectSupplierRow/_SelectSupplierRow";
import ContactRow from "../_ContactRow";
export default {
  props: {
    maptype: String,
    value: Object,

    roomBreakdownData: Object,
  },
  components: {
    SelectSupplierRow,
    ContactRow,
  },
  data() {
    return {
      selectAll: false,
      loading: false,
      supplierList: [],
    };
  },
  watch: {
    maptype(nuval) {
      this.updateSupplierList(nuval);
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
      ];
    },
    bookingSuppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    bookingDays() {
      return this.$store.getters["BookingDayStore/days"];
    },
    bookingPax() {
      return BookingRoomService.getPaxCount(
        this.$store.getters["BookingStore/data"].room_num,
        this.$store.getters["BookingRoomStore/rooms"]
      );
    },
  },
  methods: {
    onclickSupplier({ supplier, allSelected }) {
      let modifier = { ...this.value };
      if (allSelected) {
        // Remove EVERYTHING
        supplier.contacts.forEach((contact) => {
          Vue.delete(modifier.contacts, contact.id);
        });
        this.selectAll = false;
      } else {
        supplier.contacts.forEach((contact) => {
          Vue.set(modifier.contacts, contact.id, contact);
        });
      }

      this.$emit("input", modifier);
    },
    onclickContact(contact) {
      let modifier = { ...this.value };
      if (modifier.contacts[contact.id]) {
        Vue.delete(modifier.contacts, contact.id);
        this.selectAll = false;
      } else {
        Vue.set(modifier.contacts, contact.id, contact);
      }

      this.$emit("input", modifier);
    },
    updateIgnoreProduct({ product, toIgnore }) {
      let modifier = { ...this.value };
      // This will directly mutate parent object
      // But I don't care right now
      if (toIgnore) {
        Vue.set(modifier.ignoreProducts, product.ref_id, 1);
      } else {
        Vue.delete(modifier.ignoreProducts, product.ref_id);
      }
      this.$emit("input", modifier);
    },
    setGroupping({ supplier_id, groupping }) {
      let modifier = { ...this.value };
      if (!modifier.groupBy) modifier.groupBy = {};
      Vue.set(modifier.groupBy, supplier_id, groupping);
      this.$emit("input", modifier);
    },

    toggleSelectAll() {
      // Select all here
      this.selectAll = !this.selectAll;
      this.supplierList.forEach((item) => {
        this.onclickSupplier({
          supplier: item,
          allSelected: !this.selectAll,
        });
      });
      this.$emit("input", this.value);
    },

    updateSupplierList(maptype) {
      this.loading = true;
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      const days = this.$store.getters["BookingDayStore/days"];

      const cancelStrippedSuppliers = Object.values(suppliers).reduce((obj, item) => {
        if (item.status < 0) return obj;
        obj[item.id] = item;
        return obj;
      }, {});

      // Get suppliers with their contacts
      BookingEmailService.getBookingContacts(cancelStrippedSuppliers, maptype)
        .then((supplierDataList) => {
          const temp = Array.isArray(supplierDataList)
            ? supplierDataList.reduce((obj, item) => {
                return { ...obj, [item.supplier_id]: item };
              }, {})
            : {};
          this.supplierList = BookingEmailService._attachFirstDay(temp, days, cancelStrippedSuppliers);
          this.supplierList.sort((a, b) => a.name.localeCompare(b.name));
          this.supplierList.forEach((item) => {
            item.contacts.sort((a, b) => a.name.localeCompare(b.name));
          });

          this.loading = false;
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
