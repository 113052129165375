<template>
  <a-card>
    <v-row class="mx-2" justify="space-between">
      <span class="subtitle-2">Suppliers to Email</span>

      <span class="caption"> Total selected: {{ Object.keys(value).length }} </span>
    </v-row>

    <v-row v-if="!loading && !supplierList.length" class="mx-0 pa-3">
      <span>(No suppliers found. Please check Database search)</span>
    </v-row>

    <v-data-table
      v-else
      disable-pagination
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="supplierList"
      :loading="loading"
      loading-text="Fetching suppliers"
      height="500px"
      show-select
      single-select
      class="database-table a-card nested-rows"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr class="database-row" :class="{ 'primary--text grey lighten-2': selectAll }" @click="toggleSelectAll">
            <td>
              <input type="checkbox" :checked="selectAll" />
            </td>
            <td>SELECT ALL</td>
          </tr>
          <tr
            v-for="item in items"
            :key="item.id"
            class="database-row color-alt canclick"
            :class="{ 'primary--text blue lighten-4': hasAllContactsSelected(item) }"
            @click="onclickSupplier(item)"
          >
            <td class="d-flex align-start pt-2" style="max-width: 30px">
              <v-icon small v-if="!item.contacts.length">clear</v-icon>
              <input v-else type="checkbox" :checked="hasAllContactsSelected(item)" />
            </td>
            <td class="pt-1">
              <div>
                <span>{{ item.name }}</span>
                <!-- <span v-if="item.hidden" class="caption mx-2">(Hiding {{item.hidden}} contacts. No email.)</span> -->
              </div>

              <div>
                <v-row
                  v-for="contact in item.contacts"
                  :key="contact.id"
                  class="contact-row"
                  :class="{ 'primary--text blue lighten-4': value[contact.id] }"
                  @click="(e) => onclickContact(e, contact)"
                >
                  <v-col style="max-width: 30px">
                    <input type="checkbox" :checked="value[contact.id]" />
                  </v-col>
                  <v-col>
                    <span>{{ contact.name }}</span>
                    <span class="mx-2">({{ contact.email }})</span>
                    <span class="mx-2">{{ contact.title ? `(${contact.title})` : "" }}</span>
                  </v-col>
                </v-row>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </a-card>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    supplierList: Array,
    loading: Boolean,
    value: Object, // Selected supplier list
  },
  data() {
    return {
      selectAll: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
      ];
    },
  },
  methods: {
    hasAllContactsSelected(item) {
      // No valid contacts, can't select
      if (!item.contacts.length) return false;

      let allSelected = true;
      item.contacts.forEach((contact) => {
        if (!this.value[contact.id]) allSelected = false;
      });
      return allSelected;
    },
    onclickSupplier(item) {
      if (this.hasAllContactsSelected(item)) {
        // Remove EVERYTHING
        item.contacts.forEach((contact) => {
          Vue.delete(this.value, contact.id);
        });
        this.selectAll = false;
      } else {
        item.contacts.forEach((contact) => {
          Vue.set(this.value, contact.id, contact);
        });
      }

      this.$emit("input", this.value);
    },
    onclickContact(e, item) {
      e.stopPropagation();

      if (this.value[item.id]) {
        Vue.delete(this.value, item.id);
        this.selectAll = false;
      } else {
        Vue.set(this.value, item.id, item);
      }

      this.$emit("input", this.value);
    },

    toggleSelectAll() {
      if (this.selectAll) {
        // Clear select all
        this.$emit("input", {});
        this.selectAll = false;
        return;
      }

      // Select all here
      let temp = {};
      this.selectAll = true;
      this.supplierList.forEach((item) => {
        item.contacts.forEach((contact) => {
          temp[contact.id] = contact;
        });
      });
      this.$emit("input", temp);
    },
  },
};
</script>

<style lang="scss">
.nested-rows {
  .database-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
  }

  .contact-row {
    &:hover {
      background: rgba(50, 50, 50, 0.1);
    }
  }

  td {
    border-bottom: none !important;
  }
}
</style>
