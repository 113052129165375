<template>
  <div class="roomlist">
    <div class="logo">
      <span id="inspiritLogo">
        <img src="@/assets/img/Logo.png" class="logo" />
      </span>
    </div>
    <div class="room-header">
      <span class="title">ROOMING LIST GROUP {{ groupName }}</span>
    </div>

    <div class="room-content">
      <table v-for="s in sortedRoomList" :key="s.key" cellspacing="0" cellpadding="0">
        <tr>
          <th colspan="2">
            {{ getHeaderName(s.type, s.pax) }}
          </th>
        </tr>
        <tr>
          <td class="rtop rleft" style="border-bottom: 2px solid black"></td>
          <td class="px-2 rtop rright" style="border-bottom: 2px solid black">First and Last name</td>
        </tr>
        <tr v-for="(p, index) in s.rooms" :key="index">
          <td class="indexing rleft rright rbottom" :class="{ cancelled: p.meta && p.meta.cancelled }">
            {{ index + 1 }}
          </td>
          <td class="rname rbottom rright" :class="{ cancelled: p.meta && p.meta.cancelled }">
            <div v-for="(n, j) in p.nameList" :key="j">
              <div>
                <span style="padding: 0 5px">{{ n.first }}</span>
                <span class="caps">{{ n.last }}</span>
                <span v-if="n.extra" class="px-2">({{ n.extra }})</span>
                <span v-if="n.about && n.about.type === 'CHILD'" class="px-2">({{ n.about.age }} YO)</span>
              </div>
            </div>
            <div v-if="p.showDates" class="mx-3 font-italic">Dates: {{ p.dates.join(", ") }} ONLY</div>
            <div v-if="p.request" class="mx-3 font-italic">Requests: {{ p.request }}</div>
          </td>
        </tr>
      </table>

      <RoomTotal :roomList="roomList" :date="date" :specialList="specialList" />
    </div>
  </div>
</template>

<script>
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
import { BookingRoomService } from "@/modules/bookings.rooms";
import RoomTotal from "./_RoomTotal";
export default {
  props: {
    specialList: Object,
    date: [String, Array],
  },
  components: {
    RoomTotal,
  },
  computed: {
    groupName() {
      return this.$store.getters["BookingStore/active"].group_name;
    },
    roomList() {
      return this.$store.getters["BookingRoomStore/rooms"];
    },
    dayCount() {
      return this.$store.getters["BookingDayStore/dayCount"];
    },
    sortedRoomList() {
      // Include Cancelled rooms,
      return BookingRoomService.sortRoomList(this.roomList, this.specialList, this.date, false);
    },
  },
  methods: {
    getHeaderName(key, pax) {
      const type = BOOKING_ROOMS.find((item) => item.value === key);
      return `${type.alt_label}`;
    },
  },
};
</script>

<style lang="scss">
/* MUST BE CSS*/
@import "./style.css";
</style>
