<template>
  <v-col>
    <QuoteFormHeader
      :quoteId="$route.params.quote_id"
      :isSubmitDisabled="!isQuoteValid"
      :onGenerateToBooking="submitAndGenerateBooking"
      :onExportOverviewToExcel="submitAndExportOverviewToExcel"
      :onExportDetailToExcel="submitAndExportDetailToExcel"
      :onOverviewOptions="overviewOptions"
      :onDelete="deleteQuote"
      :onSubmit="submitAndRedirectToQuotes"
      :onDuplicate="submitAsNewQuote"
    />
    <v-col>
      <!-- Overview option -->
      <OverviewOptionsDialog
        v-model="isOverviewOptionDialogShowed"
        :data="data"
        @update="(v) => updateOverviewOptions(v)"
      />

      <!-- General Section -->
      <QuoteGeneral v-model="data" :supplierProductData="supplierProductData" />

      <!-- People Range Section -->
      <QuotePeopleRange v-model="data" />

      <!-- Variable Costs Section -->
      <QuoteVariableCosts
        v-model="data"
        :supplierProductData="supplierProductData"
        @onSupplierChanged="getProductsBySupplierId"
      />

      <!-- Fixed Costs Section -->
      <QuoteFixedCosts
        v-model="data"
        :supplierProductData="supplierProductData"
        @onSupplierChanged="getProductsBySupplierId"
      />

      <!-- Total Costs Section -->
      <QuoteTotalCosts v-model="data" />

      <!-- Margin Section -->
      <QuoteMargin v-model="data" />
    </v-col>
  </v-col>
</template>

<script>
const overviewOptionIncluded =
  "Le transport en motorcoach privé climatisé pour toute la durée du séjour (J 11, transfert aéroport uniquement)\n10 nuits en hôtel base chambre double - Toutes taxes incluses\nLes services d'un guide professionnel bilingue pour toute la durée du séjour\nLes services d'un guide professionnel bilingue local pour la visite du French Quarter de New Orleans\nLes repas en PC incluant 10 pdj, 09 déjeuners et 09 diners\nToutes les taxes, pourboires, eau/cafés aux repas compris\nToutes les entrées et visites mentionnées au programme.\nCotation realisée sur base de $5/gallon. Compter +0.15% du voyage par tranche d'augmentation de $0.10/gallon";

const overviewOptionExcluded =
  "Les excursions optionelles\nLes boissons autres qu'eau/cafés aux repas compris (1 biere/soda = $6 par repas; 1 biere ou 1 verre de vin = $9 par repas)\nLes pourboires aux chauffeurs et aux guides (suggestion de $9pp par jour)\nLe port des bagages (sauf si mentionné différement)";

const overviewOptionDisclaimer =
  "Tarif enfant : moins de 12 ans logeant dans la chambre des parents (1 ou 2 enfants pour 2 adultes payants)\nLe tarif enfant ne se cumule pas avec les réductions triples/quadruples\nLes prix du tableau s’entendent selon le nombre total de personnes présentes lors du voyage (adultes + enfants, hors annulation)\n10 chambres minimum requises. Les chambres triple et quadruple contiennent 2 lits double";

import moneyService from "@/services/money";
import quoteUtils from "@/modules/quotes/components/utils";
import {
  QuoteFormHeader,
  QuoteGeneral,
  QuoteVariableCosts,
  QuoteFixedCosts,
  QuoteTotalCosts,
  QuoteMargin,
  QuotePeopleRange,
  OverviewOptionsDialog,
} from "@/modules/quotes";
export default {
  components: {
    QuoteFormHeader,
    QuoteGeneral,
    QuoteVariableCosts,
    QuoteFixedCosts,
    QuoteTotalCosts,
    QuoteMargin,
    QuotePeopleRange,
    OverviewOptionsDialog,
  },
  data() {
    return {
      supplierProductData: {},
      tempVariableCosts: [],
      isOverviewOptionDialogShowed: false,
      data: {
        id: null,
        reference: null,
        client: {},
        cityStart: null,
        cityEnd: null,
        version: null,
        period: {
          startYear: new Date().getFullYear().toString(),
          startMonth: null,
          startDay: null,
          endYear: null,
          endMonth: null,
          endDay: null,
        },
        nights: null,
        accommodations: [],
        title: null,
        peopleRanges: [],
        variableCosts: [],
        fixedCosts: [],
        margin: {
          status: "fixed",
          percentFixed: 0,
          percentDynamic: [],
          perPax: [],
          perGroup: [],
        },
        overviewOptions: {
          included: overviewOptionIncluded,
          excluded: overviewOptionExcluded,
          disclaimer: overviewOptionDisclaimer,
        },
      },
    };
  },
  computed: {
    isQuoteValid() {
      return (
        this.data.reference !== null &&
        this.data.client.id !== null &&
        this.data.cityStart !== null &&
        this.data.cityEnd !== null &&
        this.data.version !== null &&
        this.data.nights !== null &&
        this.data.title !== null &&
        this.data.period.startYear !== null &&
        // this.data.period.startMonth !== null &&
        this.data.period.endYear !== null &&
        // this.data.period.endMonth !== null &&
        // this.data.variableCosts.every((variableCost) => variableCost.items.length > 0) &&
        // this.data.fixedCosts.length > 0 &&
        this.data.margin.percentFixed != null &&
        this.data.margin.percentFixed.toString() !== "" &&
        this.data.peopleRanges.length > 0 &&
        Object.values(this.data.margin.percentDynamic).every((value) => value !== null && value !== "")
      );
    },
  },
  methods: {
    updateOverviewOptions(overview) {
      this.data.overviewOptions.included = overview.overviewIncluded;
      this.data.overviewOptions.excluded = overview.overviewExcluded;
      this.data.overviewOptions.disclaimer = overview.overviewDisclaimer;
    },
    getProductsBySupplierId(supplierId) {
      this.$store
        .dispatch("ProductStore/getSupplierProductList", supplierId)
        .then((data) => {
          const products = {};
          for (const product of data) {
            products[product.id] = product;
          }
          this.$set(this.supplierProductData, supplierId, {
            supplier: this.supplierProductData[supplierId].supplier,
            products: products,
          });
        })
        .catch((error) => {
          console.error("Error fetching supplier product list:", error);
        });
    },
    submitQuote(quoteId, isDuplicated) {
      const workingEnv = this.$store.getters["AccountStore/environment"];
      const source = moneyService.getCurrencySource(workingEnv);
      const reference = isDuplicated ? this.data.reference + " - Copy" : this.data.reference;

      const body = {
        quote: {
          client_id: this.data.client.id,
          reference: reference,
          city_start: this.data.cityStart,
          city_end: this.data.cityEnd,
          version: this.data.version,
          start_date: {
            day: this.data.period.startDay,
            month: this.data.period.startMonth,
            year: this.data.period.startYear,
          },
          end_date: {
            day: this.data.period.endDay,
            month: this.data.period.endMonth,
            year: this.data.period.endYear,
          },
          number_of_nights: this.data.nights,
          accommodation_category: {
            1: this.data.accommodations.includes(1),
            1.5: this.data.accommodations.includes(1.5),
            2: this.data.accommodations.includes(2),
            2.5: this.data.accommodations.includes(2.5),
            3: this.data.accommodations.includes(3),
            3.5: this.data.accommodations.includes(3.5),
            4: this.data.accommodations.includes(4),
            4.5: this.data.accommodations.includes(4.5),
            5: this.data.accommodations.includes(5),
          },
          title: this.data.title,
          people_ranges: this.data.peopleRanges,
          total_cost_pax: this.getTotalCostByPax(),
          is_fixed_margin: this.data.margin.status == "fixed",
          margin_percent: this.data.margin.percentFixed,
          margin_pax: this.data.margin.percentDynamic.map((percentDynamic) => percentDynamic),
          overview_included: this.data.overviewOptions.included,
          overview_excluded: this.data.overviewOptions.excluded,
          overview_disclaimer: this.data.overviewOptions.disclaimer,
        },
        variable_costs: this.data.variableCosts.flatMap((variableCost, day) =>
          variableCost.items.map((item, order) => ({
            uuid: item.uuid,
            supplier_id: item.supplier.value ?? null,
            supplier_name: item.supplier.value ? null : item.supplier.label,
            supplier_city: item.supplier.value ? null : item.supplierCity,
            product_id: item.product.value ?? null,
            product_name: item.product.value ? null : item.product.label,
            product_type: item.productType,
            product_cost: {
              value: source.currency_symbol + (item.productType == "ROOM" ? item.productCost * 2 : item.productCost),
              cur: source.currency,
            },
            product_cost_is_editable: item.isProductEditableCost,
            show_in_program: item.isShowInProgram,
            day: day,
            order_in_day: order,
            fixed_cost_uuids: item.fixedCostUUIDs,
          }))
        ),

        fixed_costs: this.data.fixedCosts.map((item) => ({
          uuid: item.uuid,
          supplier_id: item.supplier.value ?? null,
          supplier_name: item.supplier.value ? null : item.supplier.label,
          supplier_city: item.supplier.value ? null : item.supplierCity,
          product_id: item.product.value ?? null,
          product_name: item.product.value ? null : item.product.label,
          product_type: item.productType,
          product_cost: {
            value: source.currency_symbol + (item.productType == "ROOM" ? item.productCost * 2 : item.productCost),
            cur: source.currency,
          },
          product_cost_is_editable: item.isProductEditableCost,
          show_in_program: item.isShowInProgram,
          days: item.days,
        })),
      };

      const dispatchAction = quoteId ? "updateQuote" : "createQuote";
      return this.$store.dispatch(`QuoteStore/${dispatchAction}`, body);
    },
    submitAndGenerateBooking() {
      this.$root.$loading.open("Generating a booking...");
      const quoteId = this.$route.params.quote_id;
      return this.submitQuote(quoteId, false)
        .then((data) => this.$store.dispatch(`QuoteStore/convertQuoteToBooking`, quoteId))
        .then((data) => {
          this.$root.$loading.end();
          this.$root.$success("Successfully converted to booking.");
          this.$router.push({ name: "bookings_view", params: { booking_id: data } });
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    submitAndRedirectToQuotes() {
      this.$root.$loading.open("Updating a quote...");
      const quoteId = this.$route.params.quote_id;
      const successMessage = quoteId ? "Update Quote" : "Created Quote";
      return this.submitQuote(quoteId, false)
        .then((data) => {
          this.$root.$loading.end();
          this.$root.$success(successMessage);
          if (quoteId == null) {
            this.$router.push({ name: "quotes" });
          }
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    submitAndExportOverviewToExcel() {
      this.$root.$loading.open("Exporting to Excel file...");
      const quoteId = this.$route.params.quote_id;
      return this.submitQuote(quoteId, false)
        .then((data) => this.$store.dispatch("QuoteStore/getOneQuote", quoteId))
        .then((data) =>
          quoteUtils.exportOverviewToExcel(
            this.$store.getters["AccountStore/environment"],
            this.supplierProductData,
            data
          )
        )
        .then((data) => {
          this.$root.$loading.end();
          this.$root.$success("Successfully exported to file.");
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    submitAndExportDetailToExcel() {
      this.$root.$loading.open("Exporting to Excel file...");
      const quoteId = this.$route.params.quote_id;
      return this.submitQuote(quoteId, false)
        .then((data) => this.$store.dispatch("QuoteStore/getOneQuote", quoteId))
        .then((data) =>
          quoteUtils.exportDetailToExcel(
            this.$store.getters["AccountStore/environment"],
            this.supplierProductData,
            data
          )
        )
        .then((data) => {
          this.$root.$loading.end();
          this.$root.$success("Successfully exported to file.");
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    overviewOptions() {
      this.isOverviewOptionDialogShowed = true;
    },
    submitAsNewQuote() {
      this.$root.$loading.open("Duplicating a quote...");

      this.data.fixedCosts.forEach((fixCost) => {
        const newUUID = quoteUtils.generateUUID();

        this.data.variableCosts.forEach((variableCost) => {
          variableCost.items.forEach((item, index) => {
            item.uuid = quoteUtils.generateUUID();
            if (item.fixedCostUUIDs != null && item.fixedCostUUIDs.includes(fixCost.uuid)) {
              item.fixedCostUUIDs[index] = newUUID;
            }
          });
        });

        fixCost.uuid = newUUID;
      });

      return this.submitQuote(null, true)
        .then((data) => {
          this.$root.$loading.end();
          this.$root.$success("Successfully duplicated a quote");
          this.$router.push({ name: "quotes" });
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    deleteQuote() {
      const quoteId = this.$route.params.quote_id;
      if (!quoteId) return;

      this.$root.$loading.open("Deleting a quote...");
      return this.$store
        .dispatch(`QuoteStore/deleteQuote`, quoteId)
        .then((v) => {
          this.$root.$loading.end();
          this.$root.$success("Successfully deleted Quote");
          this.$router.push({ name: "quotes" });
        })
        .catch((err) => this.$root.$error(err));
    },
    getTotalCostByPax() {
      return quoteUtils.getTotalCostByPax(this.data.variableCosts, this.data.fixedCosts, this.data.peopleRanges);
    },
    renderQuote(quote) {
      this.data.id = quote.quote.id;
      this.data.reference = quote.quote.reference;
      this.data.cityStart = quote.quote.city_start;
      this.data.cityEnd = quote.quote.city_end;
      this.data.version = quote.quote.version;
      this.data.nights = quote.quote.number_of_nights;
      this.data.title = quote.quote.title;
      this.data.margin.status = quote.quote.is_fixed_margin ? "fixed" : "dynamic";
      this.data.margin.percentFixed = quote.quote.margin_percent;
      this.data.client = quote.client;
      this.data.overviewOptions.included = quote.quote.overview_included;
      this.data.overviewOptions.excluded = quote.quote.overview_excluded;
      this.data.overviewOptions.disclaimer = quote.quote.overview_disclaimer;

      // Start Date
      if (quote.quote.start_date !== null) {
        const startDateObject = quote.quote.start_date;
        this.data.period.startYear = startDateObject.year;
        this.data.period.startMonth = startDateObject.month;
        this.data.period.startDay = startDateObject.day;
      }

      // End Date
      if (quote.quote.end_date !== null) {
        const endDateObject = quote.quote.end_date;
        this.data.period.endYear = endDateObject.year;
        this.data.period.endMonth = endDateObject.month;
        this.data.period.endDay = endDateObject.day;
      }

      // Accommodation Category
      if (quote.quote.accommodation_category !== null) {
        const accommodationCategoryObject = quote.quote.accommodation_category;
        const accommodationsToAdd = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"];
        accommodationsToAdd.forEach((accommodation) => {
          if (accommodationCategoryObject[accommodation]) {
            this.data.accommodations.push(parseFloat(accommodation));
          }
        });
      }

      // People Range
      if (quote.quote.people_ranges !== null) {
        const peopleRanges = JSON.parse(quote.quote.people_ranges);
        peopleRanges.forEach((peopleRange) => {
          this.data.peopleRanges.push(peopleRange);
        });
      }

      // Margin Pax
      if (quote.quote.margin_pax !== null) {
        const marginPaxs = quote.quote.margin_pax;
        marginPaxs.forEach((marginPax, index) => {
          this.data.margin.percentDynamic[index] = marginPax;
        });
      }

      // Variable Costs
      if (quote.variable_costs != null) {
        const days = {};
        for (let i = 0; i < quote.quote.number_of_nights + 1; i++) {
          days[i] = [];
        }
        quote.variable_costs.forEach((variableCost) => {
          days[variableCost.day].push(variableCost);
        });

        this.data.variableCosts = Object.keys(days).map((day) => ({
          items: days[day].map((item) => {
            const {
              uuid: itemUUID,
              supplier_id: itemSupplierId,
              supplier_name: itemSupplierName,
              supplier_city: itemSupplierCity,
              product_id: itemProductId,
              product_name: itemProductName,
              product_type: itemProductType,
              product_cost: itemProductCost,
              show_in_program: itemIsShowInProgram,
              product_cost_is_editable: itemIsProductCostEditable,
              fixed_cost_uuids: itemFixedCostUUIDs,
            } = item;

            let supplier, supplierCity, product, productType, productCost, isShowInProgram, isProductEditableCost;

            isShowInProgram = itemIsShowInProgram;
            isProductEditableCost = itemIsProductCostEditable;

            if (itemSupplierId != null) {
              const supplierInfo = this.supplierProductData[itemSupplierId].supplier;
              supplier = { label: supplierInfo.name, value: itemSupplierId, foc: supplierInfo.meta.foc };
              supplierCity = supplierInfo.city;

              const productInfo =
                itemProductId != null ? this.supplierProductData[itemSupplierId].products[itemProductId] : null;
              const productName = itemProductId != null ? productInfo.name : itemProductName;
              const productTypeValue = itemProductId != null ? productInfo.product_type : itemProductType;

              product = { label: productName, value: itemProductId };
              productType = productTypeValue;
              productCost =
                productTypeValue == "ROOM"
                  ? parseFloat(itemProductCost.value.substring(1)) / 2
                  : parseFloat(itemProductCost.value.substring(1));
            } else {
              const supplierInfo = this.supplierProductData[itemSupplierId].supplier;
              supplier = { label: itemSupplierName, value: null, foc: supplierInfo.meta.foc };
              supplierCity = itemSupplierCity;
              product = { label: itemProductName, value: null };
              productType = itemProductType;
              productCost =
                itemProductType == "ROOM" ? itemProductCost.value.substring(1) / 2 : itemProductCost.value.substring(1);
            }

            return {
              uuid: itemUUID ?? quoteUtils.generateUUID(),
              supplier: supplier,
              supplierCity: supplierCity,
              product: product,
              productType: productType,
              productCost: productCost,
              isShowInProgram: isShowInProgram,
              isProductEditableCost: isProductEditableCost,
              fixedCostUUIDs: JSON.parse(itemFixedCostUUIDs),
            };
          }),
        }));

        // Fixed Costs
        if (quote.fixed_costs != null) {
          this.data.fixedCosts = quote.fixed_costs.map((item) => {
            const {
              uuid: itemUUID,
              supplier_id: itemSupplierId,
              supplier_name: itemSupplierName,
              supplier_city: itemSupplierCity,
              product_id: itemProductId,
              product_name: itemProductName,
              product_type: itemProductType,
              product_cost: itemProductCost,
              show_in_program: itemIsShowInProgram,
              product_cost_is_editable: itemIsProductCostEditable,
              days: itemDays,
            } = item;

            let supplier, supplierCity, product, productType, productCost, isShowInProgram, isProductEditableCost;

            isShowInProgram = itemIsShowInProgram;
            isProductEditableCost = itemIsProductCostEditable;

            if (itemSupplierId != null) {
              const supplierInfo = this.supplierProductData[itemSupplierId].supplier;
              supplier = { label: supplierInfo.name, value: itemSupplierId, foc: supplierInfo.meta.foc };
              supplierCity = supplierInfo.city;

              const productInfo =
                itemProductId != null ? this.supplierProductData[itemSupplierId].products[itemProductId] : null;
              const productName = itemProductId != null ? productInfo.name : itemProductName;
              const productTypeValue = itemProductId != null ? productInfo.product_type : itemProductType;

              product = { label: productName, value: itemProductId };
              productType = productTypeValue;
              productCost =
                productTypeValue == "ROOM"
                  ? parseFloat(itemProductCost.value.substring(1)) / 2
                  : parseFloat(itemProductCost.value.substring(1));
            } else {
              const supplierInfo = this.supplierProductData[itemSupplierId].supplier;
              supplier = { label: itemSupplierName, value: null, foc: supplierInfo.meta.foc };
              supplierCity = itemSupplierCity;
              product = { label: itemProductName, value: null };
              productType = itemProductType;
              productCost =
                itemProductType == "ROOM" ? itemProductCost.value.substring(1) / 2 : itemProductCost.value.substring(1);
            }
            return {
              uuid: itemUUID ?? quoteUtils.generateUUID(),
              supplier: supplier,
              supplierCity: supplierCity,
              product: product,
              productType: productType,
              productCost: productCost,
              isShowInProgram: isShowInProgram,
              isProductEditableCost: isProductEditableCost,
              days:
                itemDays != null ? JSON.parse(itemDays) : productType === "TRANS" || productType === "TOUR" ? [] : [0],
            };
          });
        }
      }
    },
  },
  async mounted() {
    try {
      // Get Suppliers
      const suppliersResponse = await this.$store.dispatch("DatabaseStore/querySuppliers", { type: "ALL" });
      suppliersResponse.map((supplier) => this.$set(this.supplierProductData, supplier.id, { supplier: supplier }));

      // Get Quote
      if (this.$route.params.quote_id) {
        const quoteResponse = await this.$store.dispatch("QuoteStore/getOneQuote", this.$route.params.quote_id);

        let supplierIds = [];
        const filteredVariableCosts = quoteResponse.variable_costs.filter((cost) => cost.supplier_id !== null);
        const filteredFixedCosts = quoteResponse.fixed_costs.filter((cost) => cost.supplier_id !== null);
        const allFilteredCosts = filteredVariableCosts.concat(filteredFixedCosts);

        for (let i = 0; i < allFilteredCosts.length; i++) {
          supplierIds.push(allFilteredCosts[i].supplier_id);
        }

        for (let i = 0; i < supplierIds.length; i++) {
          const productsBySupplierResponse = await this.$store.dispatch(
            "ProductStore/getSupplierProductList",
            supplierIds[i]
          );

          let products = {};
          productsBySupplierResponse.map((product) => this.$set(products, product.id, product));

          this.supplierProductData[supplierIds[i]].products = products;
        }

        // Render a quote
        this.renderQuote(quoteResponse);
      } else {
        this.data.peopleRanges.push({ min: "45", max: "54" });
        this.data.peopleRanges.push({ min: "40", max: "44" });
        this.data.peopleRanges.push({ min: "35", max: "39" });
        this.data.peopleRanges.push({ min: "30", max: "34" });
        this.data.peopleRanges.push({ min: "25", max: "29" });
        this.data.peopleRanges.push({ min: "20", max: "24" });
      }
    } catch (err) {
      this.$root.$error(err);
    }
  },
};
</script>
