<template>
  <a-card nopad class="caption invoice-row">
    <v-row>
      <v-col cols="2" class="d-flex pa-0 align-center">
        <div class="ml-2">Client</div>
        <div class="font-weight-bold px-3" style="min-height: 30px; align-items: center; display: flex">
          <span>{{ client }}</span>
          <EmailClientButton class="mx-3 report-btn" :clientData="client_data" />
        </div>
      </v-col>

      <v-col class="d-flex pa-0 align-center">
        <div>Group Name</div>
        <div class="font-weight-bold px-3">
          <a @click="toBooking">{{ group_name }}</a>
        </div>
      </v-col>

      <v-col class="d-flex pa-0 align-center">
        <v-icon small class="mr-2">location_city</v-icon>
        <div>
          <span>From</span>
          <span class="font-weight-bold px-3">{{ booking_city.start }}</span>
          <span>to</span>
          <span class="font-weight-bold px-3">{{ booking_city.end }}</span>
        </div>
      </v-col>

      <v-col class="d-flex pa-0 align-center">
        <v-icon small class="mr-2">event</v-icon>
        <div>
          <span class="font-weight-bold px-3">{{ booking_dates.from }}</span>
          <span>to</span>
          <span class="font-weight-bold px-3">{{ booking_dates.to }}</span>
        </div>
      </v-col>

      <v-col cols="1" class="d-flex pa-0 align-center font-weight-bold">
        <elwrapper label="PAX" midtext smlabel>
          {{ pax }}
        </elwrapper>
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import { BookingRoomService } from "@/modules/bookings.rooms";
import { EmailClientButton } from "@/modules/finances.email";
export default {
  components: {
    EmailClientButton,
  },
  data() {
    return {
      invoiceDialog: false,
      partPaidDialog: false,
    };
  },
  computed: {
    client() {
      return this.$store.getters["FinanceReportStore/client"];
    },
    client_data() {
      return this.$store.getters["FinanceReportStore/client_data"];
    },
    group_name() {
      return this.$store.getters["FinanceReportStore/group_name"];
    },
    booking_dates() {
      return this.$store.getters["FinanceReportStore/booking_dates"];
    },
    booking_city() {
      return this.$store.getters["FinanceReportStore/booking_city"];
    },
    pax() {
      const bookingData = this.$store.getters["FinanceReportStore/bookingData"];
      return bookingData && bookingData.pax_num;
      // const booking_rooms = this.$store.getters['FinanceReportStore/booking_rooms']

      // return BookingRoomService.getPaxCount(bookingData && bookingData.room_num,
      // 	booking_rooms && booking_rooms.rooms)
    },

    booking_id() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
  },
  methods: {
    toBooking() {
      this.$router.push({
        name: "bookings_view",
        params: {
          id: this.booking_id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.invoice-row {
  .report-btn {
    display: none;
  }

  &:hover {
    .report-btn {
      display: inline;
    }
  }
}
</style>
