<template>
  <v-container class="pa-0" fluid style="height: 100%; overflow: auto; max-width: 100%; width: 100%; min-width: 100%">
    <NoEmailAlert />
    <v-row justify="space-between" class="ma-2">
      <a-card nopad>
        <g-button :disabled="emailView === 0" label="1 - Select" class="mx-1" @onclick="emailView = 0" />
        <g-button :disabled="emailView === 1" label="2 - Compose" @onclick="emailView = 1" />
        <g-button :disabled="emailView === 2" label="3 - Preview" @onclick="emailView = 2" />
      </a-card>

      <a-card nopad v-if="emailView !== 0">
        <EmailSendButton
          :disabled="disableSend"
          :subject="formData.subject"
          :attachments="formData.attachments"
          :compileMailFn="compileMailFn"
          :onFinishFn="returnTo"
        />
      </a-card>
    </v-row>

    <v-row v-show="emailView === 0">
      <SelectSupplierEmailTable
        style="flex: 1"
        :supplierList="supplierList"
        :loading="loadingSuppliers"
        v-model="selected"
      />
    </v-row>

    <v-row v-show="emailView === 1">
      <DatabaseEmailWrapper style="flex: 1" v-model="formData" />
    </v-row>
    <v-row v-if="emailView === 2">
      <a-card style="flex: 1">
        <PreviewEmailWrapper
          :subject="formData.subject"
          :attachments="formData.attachments"
          :compileMailFn="compileMailFn"
        />
      </a-card>
    </v-row>
  </v-container>
</template>

<script>
import { PreviewEmailWrapper, EmailTemplateService } from "@/modules/admin.email_template";
import { DatabaseEmailWrapper, SelectSupplierEmailTable } from "@/modules/database.email";
import { EmailService, EmailSendButton, NoEmailAlert } from "@/modules/email";

export default {
  components: {
    PreviewEmailWrapper,
    DatabaseEmailWrapper,
    SelectSupplierEmailTable,
    EmailSendButton,
    NoEmailAlert,
  },
  data() {
    return {
      supplierList: [],
      selected: {},
      formData: {},

      contactData: {},

      emailView: 0,

      loadingSuppliers: false,
    };
  },
  computed: {
    disableSend() {
      if (!this.formData.subject) return true;
      if (!this.formData.message) return true;
      return !Object.keys(this.selected).length;
    },
    clientPage() {
      return this.$route.meta.isclient;
    },
  },
  methods: {
    returnTo() {
      // this.$router.push({
      //   name: "database_view",
      //   params: {
      //     view: this.clientPage ? "clients" : "suppliers",
      //     sub: "ALL",
      //   },
      // });
    },
    compileMailFn() {
      let groupedSupplierContacts = EmailService.groupSupplierContacts(
        this.selected,
        this.clientPage ? "client_id" : "supplier_id"
      );
      const message = this.formData.message || "";

      groupedSupplierContacts.forEach((selectedSupplier) => {
        // ONLY REPLACING CONTACTS
        selectedSupplier.message = EmailTemplateService.replaceContact(
          selectedSupplier.contacts.nameList,
          message,
          this.formData.language
        );
        selectedSupplier.message = EmailTemplateService.replaceSupplier(selectedSupplier.message, selectedSupplier);
      });

      return Promise.resolve(groupedSupplierContacts);
    },

    getSupplierList() {
      // Get query
      const savedTab = this.$store.getters["DatabaseStore/activeTab"]("suppliers");

      // Existing query
      // List of suppliers + their contacts the user can select
      return this.$store.dispatch("DatabaseStore/getSupplierNames", {
        type: this.$route.params.supplier_type,
        query: savedTab.data,
      });
    },
    getClientList() {
      // Get query
      const savedTab = this.$store.getters["DatabaseStore/activeTab"]("clients");

      // Existing query
      // List of suppliers + their contacts the user can select
      return this.$store.dispatch("DatabaseStore/getClientNames", {
        query: savedTab.data,
      });
    },
  },
  mounted() {
    const promise = this.clientPage ? this.getClientList() : this.getSupplierList();

    this.loadingSuppliers = true;
    promise
      .then((rows) => {
        this.loadingSuppliers = false;
        this.selected = {};
        this.supplierList = rows;
        this.supplierList.sort((a, b) => a.name.localeCompare(b.name));
        this.supplierList.forEach((item) => {
          item.contacts.sort((a, b) => a.name.localeCompare(b.name));
        });
      })
      .catch((err) => {
        this.loadingSuppliers = false;
        this.$root.$error(err);
      });
  },
};
</script>
