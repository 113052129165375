<template>
  <a-card>
    <v-row>
      <a-field :config="config.address" v-model="address" class="px-1" style="width: 100%" />
    </v-row>

    <v-row>
      <v-col class="py-0">
        <a-field :config="config.city" v-model="city" />
      </v-col>
      <v-col class="py-0">
        <a-field :config="config.subcity" v-model="subcity" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <a-field :config="config.subjur" :env="env" :country="country" :dependantValue="country" v-model="subjur" />
      </v-col>
      <v-col class="py-0">
        <a-field
          v-if="supplierType === 'TOUR_GUIDE'"
          widelabel
          :config="config.op_area"
          :customSource="operatingAreaOptions"
          v-model="op_area"
        />
        <a-field
          v-else
          :config="config.region"
          v-model="region"
          :dependantValue="country"
          :env="env"
          :country="country"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <a-field :env="env" :src="supplierType" :config="config.country" v-model="country" />
      </v-col>
      <v-col class="py-0">
        <a-field :config="config.zip" v-mask="zipPostalMask || '######'" v-model="zip_code" />
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import { mask } from "vue-the-mask";
import info from "../config/formConfig";
import PLACES from "@/config/fields/PLACES";

export default {
  props: {
    supplierType: String,
  },
  directives: {
    mask,
  },
  computed: {
    config() {
      return this.supplierType ? info[this.supplierType] || {} : {};
    },
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    address: {
      get() {
        return this.get("address");
      },
      set(newValue) {
        return this.set("address", newValue);
      },
    },
    city: {
      get() {
        return this.get("city");
      },
      set(newValue) {
        return this.set("city", newValue);
      },
    },
    subcity: {
      get() {
        return this.get("subcity");
      },
      set(newValue) {
        return this.set("subcity", newValue);
      },
    },
    region: {
      get() {
        return this.get("region");
      },
      set(newValue) {
        if (newValue === null) this.subjur = null;
        return this.set("region", newValue);
      },
    },
    op_area: {
      get() {
        return this.get("op_area");
      },
      set(newValue) {
        return this.set("op_area", newValue);
      },
    },
    operatingAreaOptions() {
      return this.$store.getters["ListStore/OPERATING_AREA"](this.env);
    },
    subjur: {
      get() {
        return this.get("subjur");
      },
      set(newValue) {
        if (["US", "JP"].includes(this.country)) {
          this.set("region", PLACES.REGIONS[this.country].STATE_TO_REGION_MAP[newValue]);
        }
        return this.set("subjur", newValue);
      },
    },
    country: {
      get() {
        return this.get("country");
      },
      set(newValue) {
        return this.set("country", newValue);
      },
    },
    zip_code: {
      get() {
        return this.get("zip_code");
      },
      set(newValue) {
        return this.set("zip_code", newValue);
      },
    },

    zipPostalMask() {
      const ref = {
        US: ["#####", "#####-####"],
        CA: ["XXX XXX"],
        JP: ["###-####"],
      };
      return ref[this.country] || ref["US"];
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["SupplierStore/" + val];
    },
    set(key, val) {
      return this.$store.dispatch("SupplierStore/setUpdate", { [key]: val });
    },

    syncRegion(key, value) {
      // let copy = { ...this.value }
      // this.$emit('input', Object.assign(copy, {
      //   [key]: value,
      //   ...(key==='region' && value === null) && { subjur: null }
      // }))
      // if(key === 'subjur' && ['US', 'JP'].includes(this.value.country) ){
      //   this.$emit('input', Object.assign(copy, {
      //     region: PLACES.REGIONS[this.value.country].STATE_TO_REGION_MAP[value]
      //   }))
      // }
    },
  },
};
</script>
