<template>
  <elwrapper :label="label" midtext
    ><v-row
      ><f-number
        v-model="paxValue"
        :disabled="disabled"
        smlabel
        @focus="(e) => $emit('focus', e)"
        @blur="(e) => $emit('blur', e)"
      />
      <div class="ml-2">{{ unit }}</div></v-row
    ></elwrapper
  >
</template>

<script>
export default {
  props: {
    label: String,
    value: null,
    unit: String,
    disabled: Boolean,
  },
  computed: {
    paxValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
