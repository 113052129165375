<template>
  <a-card>
    <a-select widelabel label="Supplier Type *" :items="supplierTypes" v-model="type" @input="clearSelected" />

    <SelectSupplierTable
      :maptype="type"
      :value="value"
      :roomBreakdownData="roomBreakdownData"
      @input="(v) => $emit('input', v)"
    />
  </a-card>
</template>

<script>
import { SuppliersMenu } from "@/config/menus";
import SelectSupplierTable from "./_SelectSupplierTable";
export default {
  props: {
    day: String,
    value: Object,

    roomBreakdownData: Object,
  },
  components: {
    SelectSupplierTable,
  },
  data() {
    return {
      type: "",
    };
  },
  computed: {
    supplierTypes() {
      var list = [];
      SuppliersMenu.FULL.forEach((item) => {
        if (item.module === "ALL") return;
        list.push({
          label: item.title,
          value: item.module,
          dbkey: item.alt,
        });
      });
      return list;
    },
  },
  methods: {
    clearSelected(type) {
      this.$emit("input", { contacts: {}, ignoreProducts: {}, groupBy: {} });
      this.$emit(
        "setType",
        this.supplierTypes.find((x) => x.value === type)
      );
    },
  },
};
</script>
