import Vue from "vue";
import api from "./api";

const SET_PRODUCT = "SET_PRODUCT";
const UPDATE_PRODUCT = "UPDATE_PRODUCT";
const UPDATE_PRODUCT_RATE = "UPDATE_PRODUCT_RATE";
const UPDATE_PRODUCT_META = "UPDATE_PRODUCT_META";
const TRANSFER_PRODUCT = "TRANSFER_PRODUCT";
const ADD_PRODUCT_RATE_HISTORY = "ADD_PRODUCT_RATE_HISTORY";

const namespaced = true;

const state = {
  current: _getProduct(),
  cached: _getProduct(),
};

function _getProduct() {
  return JSON.parse(localStorage.getItem("product") || "{}");
}

const mutations = {
  SET_PRODUCT(state, data) {
    state.current = data || {};
    state.cached = JSON.parse(JSON.stringify(state.current));
    localStorage.setItem("product", JSON.stringify(state.current));
  },
  UPDATE_PRODUCT(state, data) {
    Vue.set(state.current, data.key, data.val);
  },
  UPDATE_PRODUCT_RATE(state, { key, val, year }) {
    if (!year || !state.current || !state.current.rate_history) return;
    const temp = state.current.rate_history.find((item) => item.rate_year === year);

    Vue.set(temp, key, val);
  },
  UPDATE_PRODUCT_META(state, { key, value, year }) {
    if (!year || !state.current || !state.current.rate_history) return;
    const temp = state.current.rate_history.find((item) => item.rate_year === year);
    if (!temp.meta) temp.meta = {};
    Vue.set(temp.meta, key, value);
  },
  TRANSFER_PRODUCT(state) {
    state.cached = JSON.parse(JSON.stringify(state.current));
    localStorage.setItem("product", JSON.stringify(state.current));
  },

  ADD_PRODUCT_RATE_HISTORY(state, data) {
    if (!state.current.rate_history) Vue.set(state.current, "rate_history", []);
    state.current.rate_history.push(data);
    localStorage.setItem("product", JSON.stringify(state.current));
  },
};

const actions = {
  setProduct({ commit }, data) {
    commit(SET_PRODUCT, data);
  },
  setUpdate({ commit }, data) {
    commit(UPDATE_PRODUCT, data);
  },
  setUpdateRate({ commit }, { key, val, year }) {
    commit(UPDATE_PRODUCT_RATE, { key, val, year });
  },
  setUpdateMeta({ commit }, { key, value, year }) {
    commit(UPDATE_PRODUCT_META, { key, value, year });
  },
  getSupplierProductList({ commit }, supplier_id) {
    return api
      .getSupplierProducts(supplier_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  getProduct({ commit }, product_id) {
    return api
      .getProduct(product_id)
      .then((data) => {
        commit(SET_PRODUCT, data.data);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  copyOneProduct({ commit }, product_id) {
    return api
      .copyOneProduct(product_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  copyProducts({ commit }, data) {
    return api
      .copyProducts(data.to_supplier_id, data.from_supplier_id)
      .then((data) => {
        commit(SET_PRODUCT, data.data);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  updateProduct({ commit, state }, data) {
    let update = data ? data : state.current;

    return api
      .updateProduct(update.id, update, state.cached)
      .then((data) => commit(TRANSFER_PRODUCT))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateProductRate({ commit, state }, rate_year) {
    const data = extractRate(state.current.rate_history, rate_year);

    return api.updateProductRate(state.current.id, rate_year, data).catch((err) => {
      if (err) throw err.data;
    });
  },
  updateProductRateCurrentAndNextYear({ commit, state }) {
    const year = new Date().getFullYear();
    const cur = `${year}`;
    const nexty = `${year + 1}`;

    const curData = state.current.rate_history.find((item) => item.rate_year === cur);
    const nextData = state.current.rate_history.find((item) => item.rate_year === nexty);

    const curCached = state.cached.rate_history.find((item) => item.rate_year === cur);
    const nextCache = state.cached.rate_history.find((item) => item.rate_year === nexty);

    let query = [];
    if (curData) query.push(api.updateProductRate(state.current.id, cur, curData, curCached));
    if (nextData) query.push(api.updateProductRate(state.current.id, nexty, nextData, nextCache));

    return Promise.all(query).catch((err) => {
      if (err) throw err.data;
    });
  },
  updateProductGroupFit({ commit, state }, data) {
    let update = state.current;

    commit(UPDATE_PRODUCT, { key: "group_fit", val: data.group_fit });
    commit(UPDATE_PRODUCT_META, {
      key: "group_size",
      value: data.group_fit === "GROUP" ? data.group_size : 1,
    });

    return api
      .updateProduct(update.id, state.current, state.cached)
      .then((data) => commit(TRANSFER_PRODUCT))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateProductType({ commit, state }, nuType) {
    let update = state.current;

    // Need to update PRODUCT_TYPE and PID
    commit(UPDATE_PRODUCT, { key: "product_type", val: nuType });

    return api
      .updateProduct(update.id, state.current, state.cached)
      .then((nuPID) => {
        // PID is auto-updated on the backend when product type changes
        commit(UPDATE_PRODUCT, { key: "pid", val: nuPID.data });
        commit(TRANSFER_PRODUCT);

        return nuPID.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateProductYear({ commit, state }) {
    return api
      .addProductYear(state.current.id)
      .then((data) => {
        commit(ADD_PRODUCT_RATE_HISTORY, data.data);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  addProductYearBySupplier({ commit }, supplier_id) {
    return api
      .addProductYearBySupplier(supplier_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  reorderProducts({ commit }, { supplierId, updatedProductList }) {
    return api.updateProductOrder(supplierId, updatedProductList).catch((err) => {
      if (err) throw err.data;
    });
  },
  deleteProduct({ commit, state }, id) {
    var delid = id ? id : state.current.id;
    return api
      .deleteProductList([delid])
      .then(() => {
        if (state.current.id == delid) {
          commit(SET_PRODUCT, null);
        }
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteProductList({ commit }, productIdList) {
    return api
      .deleteProductList(productIdList)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  // Check if supplier is used in booking
  checkProductInBooking({ commit }, product_id) {
    return api
      .checkProductInBooking(product_id)
      .then((res) => res.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  id: (state) => state.current.id || "",
  pid: (state) => state.current.pid || "",
  name: (state) => state.current.name || "",
  type: (state) => state.current.product_type || "",
  active: (state) => state.current.active || 0,

  country: (state) => state.current.country || "",
  rate_history: (state) => state.current.rate_history || [],

  // Pricing and stuff
  rateDataByYear: (state) => (year) => extractRate(state.current.rate_history, year),
  rate: (state) => (year) => extractRate(state.current.rate_history, year).rate || {},
  seasonal: (state) => (year) => extractRate(state.current.rate_history, year).seasonal || "",
  confirmed: (state) => (year) => extractRate(state.current.rate_history, year).confirmed || 0,

  // Tax stuff
  tax_inclusive: (state) => (year) => extractRate(state.current.rate_history, year).tax_inclusive || false,
  custom_tax: (state) => (year) => {
    const focusRate = extractRate(state.current.rate_history, year);
    if (!focusRate.meta) return false;
    if (!("custom_tax" in focusRate.meta)) return false;

    return focusRate.meta.custom_tax;
  },

  // Group Stuff
  group_fit: (state) => state.current.group_fit || "",
  group_size: (state) => (year) => {
    const focusRate = extractRate(state.current.rate_history, year);
    return focusRate.meta ? focusRate.meta.group_size || 1 : "";
  },

  meta: (state) => (year) => {
    return extractRate(state.current.rate_history, year).meta || {};
  },

  // Notes
  default_technical: (state) => state.current.default_technical || "",
  notes: (state) => state.current.notes || "",
  technical_notes: (state) => state.current.technical_notes || "",
  menu_notes: (state) => state.current.menu_notes || "",

  // Supplier
  supplier_id: (state) => state.current.supplier_id || "",
  supplier_name: (state) => state.current.supplier_name || "",
  supplier_type: (state) => state.current.supplier_type || "",
  supplier_types: (state) => {
    if (!state.current.supplier_type) return [];
    const other = state.current.search_types || [];
    return [state.current.supplier_type, ...other];
  },
  supplier_meta: (state) => state.current.supplier_meta || {},
  supplier_env: (state) => state.current.supplier_env,

  hasChanges: (state) => {
    let current = JSON.stringify(state.current);
    let cached = JSON.stringify(state.cached);
    if (current === cached) return false;
    return true;
  },
};

function extractRate(rate_history, year) {
  const rh = rate_history || [];
  const r = rh.find((item) => item.rate_year === year);
  return r ? r : {};
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
