<template>
  <a-card>
    <v-row class="mx-2 py-2" justify="space-between">
      <span class="subtitle-2">Bookings</span>
    </v-row>

    <SupplierSearchBreakdown :searchRecord="searchRecord" />

    <div v-if="!bookingList.length">
      <span class="caption px-3">No Suppliers Assigned</span>
    </div>

    <BookingSupplierTable
      v-else
      :value="value"
      @input="(v) => $emit('input', v)"
      :dataList="bookingList"
      :searchRecord="searchRecord"
      :contactList="contacts"
      :loading="loading"
    />
  </a-card>
</template>

<script>
import Vue from "vue";
import BookingEmailService from "../../service";
import BookingSupplierTable from "./_BookingSupplierTable";
import SupplierSearchBreakdown from "../_SupplierSearchBreakdown";
export default {
  props: {
    value: Object,
  },
  components: {
    BookingSupplierTable,
    SupplierSearchBreakdown,
  },
  data() {
    return {
      selectAll: false,
      loading: false,

      searchRecord: null,
      bookingList: [],
      contacts: [],
    };
  },
  mounted() {
    this.loadingSuppliers = true;
    // Get query
    const savedTab = this.$store.getters["BookingEmailStore/activeTab"];
    const record = this.$store.getters["BookingEmailStore/" + savedTab + "Search"];

    this.searchRecord = record;

    // Existing query
    // List of suppliers + their contacts the user can select
    this.$store
      .dispatch("BookingEmailStore/getMassBookingContacts", record)
      .then((data) => {
        this.loadingSuppliers = false;
        this.selected = {};

        this.bookingList = data.bookings;
        this.contacts = data.contacts;

        this.$emit("setBookingRef", data.bookings);
      })
      .catch((err) => {
        this.loadingSuppliers = false;
        this.$root.$error(err);
      });
  },
};
</script>
