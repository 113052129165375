<template>
  <a-card>
    <v-col>
      <v-row><h3>Variable Costs</h3></v-row>
      <div v-for="(day, dayIndex) in data.variableCosts" :key="dayIndex" class="mt-4">
        <v-row>
          <v-btn icon small @click="reorderDayDown(dayIndex)">
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
          <v-btn icon small @click="reorderDayUp(dayIndex)">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <div>Day {{ dayIndex + 1 }} {{ formatUnixTimestampToLocaleString(day.unixTimeStamp) }}</div></v-row
        >
        <table class="ml-4">
          <thead v-if="day.items.length > 0">
            <tr>
              <th></th>
              <th>Supplier City</th>
              <th>Supplier Name</th>
              <th>Product Name</th>
              <th>Product Type</th>
              <th>Product Cost</th>
              <th></th>
              <th class="px-2">Show in<br />the program</th>
              <th class="px-2">Duplicate <br />on next day</th>
              <th class="px-2">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, itemIndex) in day.items" :key="itemIndex">
              <td align="center">
                <v-btn icon small @click="reorderItemDown(dayIndex, itemIndex)">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
                <v-btn icon small @click="reorderItemUp(dayIndex, itemIndex)">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
              </td>
              <td>
                <f-text v-model="item.supplierCity" smlabel v-caps :disabled="isCityDisabled(dayIndex, itemIndex)" />
              </td>
              <td>
                <autocomplete-free-text
                  :items="getSupplierItems(item.productType)"
                  v-model="item.supplier"
                  @input="onSupplierChanged(dayIndex, itemIndex, $event)"
                />
              </td>

              <td>
                <autocomplete-free-text
                  :items="getProductItemsBySupplier(item.supplier)"
                  v-model="item.product"
                  @input="onProductChanged(dayIndex, itemIndex, $event)"
                  :disabled="isProductNameDisabled(dayIndex, itemIndex)"
                />
              </td>
              <td>
                <f-dropdown
                  v-model="item.productType"
                  :items="getProductTypes"
                  :disabled="isProductTypeDisabled(dayIndex, itemIndex)"
                  @input="onProductTypeChanged(dayIndex, itemIndex, $event)"
                />
              </td>
              <td>
                <f-number v-model="item.productCost" smlabel :disabled="isCostDisabled(dayIndex, itemIndex)" />
              </td>
              <td>
                <v-btn
                  icon
                  small
                  :disabled="isProductEditableCostDisabled(dayIndex, itemIndex)"
                  @click="updateEditableCostStatus(dayIndex, itemIndex)"
                >
                  <v-icon>{{ getEditableCostIconButton(dayIndex, itemIndex) }}</v-icon>
                </v-btn>
              </td>
              <td>
                <div class="d-flex justify-center">
                  <v-switch :height="0" v-model="item.isShowInProgram" />
                </div>
              </td>
              <td align="center">
                <v-btn icon small @click="duplicateVariableCostToNextDay(dayIndex, itemIndex)">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </td>
              <td align="center">
                <v-btn icon small @click="removeVariableCost(dayIndex, itemIndex)">
                  <v-icon>clear</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <v-btn small text color="primary" @click="addVariableCost(dayIndex)">+ Add new line</v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-col>
  </a-card>
</template>

<script>
import { ProductMenu } from "@/config/menus";
import quoteUtils from "@/modules/quotes/components/utils";
export default {
  props: {
    value: Object,
    supplierProductData: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    getProductTypes() {
      return ProductMenu.map((product) => ({ label: product.title, value: product.module }));
    },
  },
  methods: {
    addVariableCost(dayIndex) {
      this.data.variableCosts[dayIndex].items.push({
        uuid: quoteUtils.generateUUID(),
        isShowInProgram: true,
        productCost: 0,
        isProductEditableCost: true,
        fixedCostUUIDs: [],
      });
    },
    duplicateVariableCostToNextDay(dayIndex, itemIndex) {
      const { items } = this.data.variableCosts[dayIndex];
      if (dayIndex + 1 < this.data.variableCosts.length) {
        const copyData = items[itemIndex];
        this.data.variableCosts[dayIndex + 1].items.push({ ...copyData });
      }
    },
    removeVariableCost(dayIndex, itemIndex) {
      this.data.variableCosts[dayIndex].items.splice(itemIndex, 1);
    },
    reorderDayDown(dayIndex) {
      const nextDayIndex = dayIndex + 1;
      if (nextDayIndex >= this.data.variableCosts.length) return;

      this.swapDays(dayIndex, nextDayIndex);
    },
    reorderDayUp(dayIndex) {
      const nextPreviousIndex = dayIndex - 1;
      if (nextPreviousIndex < 0) return;

      this.swapDays(dayIndex, nextPreviousIndex);
    },
    reorderItemDown(dayIndex, itemIndex) {
      const nextItemIndex = itemIndex + 1;
      if (nextItemIndex >= this.data.variableCosts[dayIndex].items.length) return;

      this.swapItems(dayIndex, itemIndex, nextItemIndex);
    },
    reorderItemUp(dayIndex, itemIndex) {
      const previousItemIndex = itemIndex - 1;
      if (previousItemIndex < 0) return;

      this.swapItems(dayIndex, itemIndex, previousItemIndex);
    },
    swapDays(dayItem1, dayItem2) {
      const temp = JSON.parse(JSON.stringify(this.data.variableCosts[dayItem1]));

      this.data.variableCosts[dayItem1].index = this.data.variableCosts[dayItem2].index;
      this.data.variableCosts[dayItem1].unixTimeStamp = this.data.variableCosts[dayItem2].unixTimeStamp;
      this.data.variableCosts[dayItem1].items = [...this.data.variableCosts[dayItem2].items];

      this.data.variableCosts[dayItem2].index = temp.index;
      this.data.variableCosts[dayItem2].unixTimeStamp = temp.unixTimeStamp;
      this.data.variableCosts[dayItem2].items = [...temp.items];
    },
    swapItems(dayIndex, itemIndex1, itemIndex2) {
      const temp = JSON.parse(JSON.stringify(this.data.variableCosts[dayIndex].items[itemIndex1]));

      this.data.variableCosts[dayIndex].items[itemIndex1].uuid =
        this.data.variableCosts[dayIndex].items[itemIndex2].uuid;
      this.data.variableCosts[dayIndex].items[itemIndex1].supplier =
        this.data.variableCosts[dayIndex].items[itemIndex2].supplier;
      this.data.variableCosts[dayIndex].items[itemIndex1].supplierCity =
        this.data.variableCosts[dayIndex].items[itemIndex2].supplierCity;
      this.data.variableCosts[dayIndex].items[itemIndex1].product =
        this.data.variableCosts[dayIndex].items[itemIndex2].product;
      this.data.variableCosts[dayIndex].items[itemIndex1].productType =
        this.data.variableCosts[dayIndex].items[itemIndex2].productType;
      this.data.variableCosts[dayIndex].items[itemIndex1].productCost =
        this.data.variableCosts[dayIndex].items[itemIndex2].productCost;
      this.data.variableCosts[dayIndex].items[itemIndex1].isShowInProgram =
        this.data.variableCosts[dayIndex].items[itemIndex2].isShowInProgram;
      this.data.variableCosts[dayIndex].items[itemIndex1].isProductEditableCost =
        this.data.variableCosts[dayIndex].items[itemIndex2].isProductEditableCost;
      this.data.variableCosts[dayIndex].items[itemIndex1].fixedCostUUIDs =
        this.data.variableCosts[dayIndex].items[itemIndex2].fixedCostUUIDs;

      this.data.variableCosts[dayIndex].items[itemIndex2].uuid = temp.uuid;
      this.data.variableCosts[dayIndex].items[itemIndex2].supplier = temp.supplier;
      this.data.variableCosts[dayIndex].items[itemIndex2].supplierCity = temp.supplierCity;
      this.data.variableCosts[dayIndex].items[itemIndex2].product = temp.product;
      this.data.variableCosts[dayIndex].items[itemIndex2].productType = temp.productType;
      this.data.variableCosts[dayIndex].items[itemIndex2].productCost = temp.productCost;
      this.data.variableCosts[dayIndex].items[itemIndex2].isShowInProgram = temp.isShowInProgram;
      this.data.variableCosts[dayIndex].items[itemIndex2].isProductEditableCost = temp.isProductEditableCost;
      this.data.variableCosts[dayIndex].items[itemIndex2].fixedCostUUIDs = temp.fixedCostUUIDs;
    },
    isCityDisabled(dayIndex, itemIndex) {
      const item = this.data.variableCosts[dayIndex].items[itemIndex];
      const supplier = item?.supplier;
      return supplier == null || supplier.label == null || supplier.value != null;
    },
    isProductNameDisabled(dayIndex, itemIndex) {
      return this.data.variableCosts[dayIndex]?.items[itemIndex]?.supplier == null;
    },
    isProductTypeDisabled(dayIndex, itemIndex) {
      return this.data.variableCosts[dayIndex].items[itemIndex].product?.value != null;
    },
    isCostDisabled(dayIndex, itemIndex) {
      return !this.data.variableCosts[dayIndex].items[itemIndex].isProductEditableCost;
    },
    isProductEditableCostDisabled(dayIndex, itemIndex) {
      return this.data.variableCosts[dayIndex].items[itemIndex].product?.value == null;
    },
    getEditableCostIconButton(dayIndex, itemIndex) {
      return this.data.variableCosts[dayIndex].items[itemIndex].isProductEditableCost
        ? "mdi-lock-outline"
        : "mdi-lock-off-outline";
    },
    updateEditableCostStatus(dayIndex, itemIndex) {
      const item = this.data.variableCosts[dayIndex].items[itemIndex];
      const newIsProductEditableCost = !item.isProductEditableCost;
      item.isProductEditableCost = newIsProductEditableCost;

      if (!newIsProductEditableCost && item.product.value != null) {
        const product = this.supplierProductData[item.supplier.value].products[item.product.value];
        const totalCost = quoteUtils.getTotalCost(this.supplierProductData, product);
        item.productCost = product.product_type == "ROOM" ? totalCost / 2 : totalCost;
      }
    },
    formatUnixTimestampToLocaleString(unixTimeStamp) {
      if (unixTimeStamp == null) return "";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate = new Date(unixTimeStamp).toLocaleDateString("en-US", options);
      return ": " + formattedDate;
    },
    getSupplierItems(productType) {
      const supplierType = quoteUtils.getSupplierTypeByProductType(productType);
      return Object.values(this.supplierProductData)
        .filter((item) => {
          return !supplierType || item.supplier.supplier_type === supplierType;
        })
        .map((item) => {
          const { supplier_type, meta } = item.supplier;
          if (["HOTEL"].includes(supplier_type) && !this.data.accommodations.includes(meta.hotel_class)) {
            return null;
          }
          return item;
        })
        .filter((item) => item !== null)
        .map((item) => {
          const { id, meta, city, name } = item.supplier;
          const hotelClass = meta?.hotel_class ? `(${meta.hotel_class}) ${"*".repeat(meta.hotel_class)}` : null;
          const cityName = city ? `${city}` : null;
          const label = `${name}${hotelClass ? ` - ${hotelClass}` : ""}${cityName ? ` - ${cityName}` : ""}`;
          return { label: label, value: id, foc: meta.foc };
        });
    },
    getProductItemsBySupplier(supplier) {
      if (supplier == null) return [];
      if (supplier.value == null) return [];
      if (this.supplierProductData[supplier.value].products == null) return [];
      return Object.values(this.supplierProductData[supplier.value].products).map((item) => {
        const { id, name } = item;
        return { label: name, value: id };
      });
    },
    onSupplierChanged(dayIndex, itemIndex, v) {
      const item = this.data.variableCosts[dayIndex].items[itemIndex];
      item.product = null;
      // item.productType = null;
      item.productCost = 0;
      item.isProductEditableCost = true;

      if (v.label === "" && v.value === null) {
        item.supplierCity = "";
        item.supplier = null;
      } else {
        item.supplier = v;
        if (v.value !== null) {
          item.supplierCity = (this.supplierProductData[v.value].supplier.city ?? "").toUpperCase();
          this.$emit("onSupplierChanged", v.value);
        }
      }
    },
    onProductChanged(dayIndex, itemIndex, v) {
      const item = this.data.variableCosts[dayIndex].items[itemIndex];
      item.product = v;

      if (v.value != null && item.supplier.value != null) {
        const product = this.supplierProductData[item.supplier.value].products[v.value];
        const totalCost = quoteUtils.getTotalCost(this.supplierProductData, product);
        item.productType = product.product_type;
        item.productCost = item.productType == "ROOM" ? totalCost / 2 : totalCost;
      }

      item.isProductEditableCost = v.value == null;
    },
    onProductTypeChanged(dayIndex, itemIndex, v) {
      const item = this.data.variableCosts[dayIndex].items[itemIndex];
      item.product = null;
      item.productType = v;
    },
  },
};
</script>
