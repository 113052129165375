<template>
  <a-card>
    <v-col>
      <h3>Total Costs</h3>
      <QuotePax
        v-for="(peopleRange, index) in data.peopleRanges"
        :key="index"
        :label="`${peopleRange.min}-${peopleRange.max} PAX`"
        :value="Number(getTotalCostByPax()[index]).toFixed(2)"
        :disabled="true"
        :unit="'per PAX'"
      />
    </v-col>
  </a-card>
</template>

<script>
import QuotePax from "./QuotePax";
import quoteUtils from "@/modules/quotes/components/utils";
export default {
  components: {
    QuotePax,
  },
  props: {
    value: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    getTotalCostByPax() {
      return quoteUtils.getTotalCostByPax(this.data.variableCosts, this.data.fixedCosts, this.data.peopleRanges);
    },
  },
};
</script>
