import axios from "axios";
import isDev from "@/services/isDevProcess";

const DEV_URL = "http://localhost:3000/api";
// const DEV_URL = "https://placement-export-again-overview.trycloudflare.com/api";
const PROD_URL = "https://service.supernature.travel/api";

const instance = axios.create({
  baseURL: isDev() ? DEV_URL : PROD_URL,
  timeout: 0, // No timeout
  headers: {
    "X-Electron-Mask": 1,
  },
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    var token = localStorage.getItem("token");
    config.headers.common["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

export default instance;
