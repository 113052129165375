<template>
  <div>
    <a-card>
      <v-row>
        <v-col class="py-0">
          <a-text-field
            label="Name *"
            :value="value.name"
            @input="(v) => feedUp('name', v)"
            v-uppercase="!showTranslation"
          />
        </v-col>
        <v-col class="py-0">
          <a-select
            widelabel
            label="Supplier Type *"
            :items="supplierTypes"
            :value="value.type"
            @input="(v) => feedUp('type', v)"
          />
        </v-col>
      </v-row>

      <v-divider />

      <div v-if="value.type">
        <v-row>
          <v-col class="py-0">
            <a-field
              req
              :env="env"
              :src="value.type"
              :config="config.country"
              :value="value.country"
              @input="(v) => feedUp('country', v)"
            />
          </v-col>
          <v-col class="py-0">
            <a-field
              class="s-field"
              v-if="value.type === 'TOUR_GUIDE'"
              :config="config.op_area"
              v-caps
              req
              :value="value.op_area"
              @input="(v) => feedUp('op_area', v)"
            />
            <a-field
              class="s-field"
              v-else
              req
              :config="config.region"
              :country="value.country"
              :dependantValue="value.country"
              :value="value.region"
              @input="(v) => feedUp('region', v)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <a-field
              noflex
              :req="validRegion"
              :config="config.subjur"
              :env="env"
              :country="value.country"
              :dependantValue="value.country"
              :value="value.subjur"
              @input="(v) => feedUp('subjur', v)"
            />
          </v-col>

          <v-col class="py-0">
            <div v-if="validRegion">
              <a-field noflex req :config="config.city" :value="value.city" @input="(v) => feedUp('city', v)" />
              <a-field noflex :config="config.subcity" :value="value.subcity" @input="(v) => feedUp('subcity', v)" />
            </div>
            <elwrapper v-else-if="value.region !== 'NAT'">
              <span class="font-italic caption">(Select Region)</span>
            </elwrapper>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <span class="caption">(Select Product Type to enter address)</span>
      </div>
    </a-card>

    <a-card>
      <v-row>
        <v-col class="py-0">
          <elwrapper icon="phone" preicon="business">
            <FPhone
              :country="value.country"
              :value="value.business_number"
              @input="(v) => feedUp('business_number', v)"
            />
          </elwrapper>
        </v-col>
        <v-col>
          <a-field :config="config.website" :value="value.website" @input="(v) => feedUp('website', v)" />
        </v-col>
      </v-row>
    </a-card>
  </div>
</template>

<script>
import { SupplierFields } from "@/config/fields/suppliers";
import { SuppliersMenu } from "@/config/menus";
import PLACES from "@/config/fields/PLACES";

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      config: SupplierFields,
    };
  },
  computed: {
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    validRegion() {
      return (this.value.region && this.value.region !== "NAT") || this.value.type === "TOUR_GUIDE";
    },
    supplierTypes() {
      var list = [];
      SuppliersMenu.FULL.forEach((item) => {
        if (item.module === "ALL") return;

        list.push({
          label: item.title,
          value: item.module,
        });
      });
      return list;
    },
    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
  },
  methods: {
    feedUp(key, value) {
      let copy = { ...this.value };
      this.$emit(
        "input",
        Object.assign(copy, {
          [key]: value,
          ...(key === "region" && value === null && { subjur: null }),
        })
      );

      if (key === "subjur" && ["US", "JP"].includes(this.value.country)) {
        this.$emit(
          "input",
          Object.assign(copy, {
            region: PLACES.REGIONS[this.value.country].STATE_TO_REGION_MAP[value],
          })
        );
      }
    },
  },
};
</script>
