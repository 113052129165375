<template>
  <div>
    <v-btn text icon x-small color="yellow darken-4" @click="toggle" :loading="loading">
      <v-icon v-if="selected">star_rate</v-icon>
      <v-icon v-else>star_border</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    selected: Number,
    type: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    toggle(e) {
      e.stopPropagation();

      this.loading = true;
      const newValue = this.selected ? 0 : 1; // Invert it

      if (this.type == "supplier") {
        this.$store
          .dispatch("SupplierStore/updateSupplierById", {
            supplierId: this.id,
            data: {
              favorite: newValue,
            },
            cache: {
              favorite: this.selected ? 1 : 0,
            },
          })
          .then((v) => {
            this.loading = false;
            this.$emit("update", newValue);
          })
          .catch((err) => this.$root.$error(err));
      } else if (this.type == "quote") {
        this.$store
          .dispatch("QuoteStore/updateQuoteById", {
            quote_id: this.id,
            data: { quote: { is_favorite: newValue } },
          })
          .then((v) => {
            this.loading = false;
            this.$emit("update", newValue);
          })
          .catch((err) => this.$root.$error(err));
      } else {
        // Do nothing
      }
    },
  },
};
</script>
