<template>
  <a-card class="mx-2">
    <v-form @submit.prevent="updateSearch">
      <v-row>
        <v-col><ClientAutocomplete v-model="client" /></v-col>
        <v-col><a-text-field label="Reference" v-model="reference" midtext /></v-col>
        <v-col
          ><elwrapper label="Year" midtext><year-picker v-model="year" /></elwrapper
        ></v-col>
      </v-row>
      <v-row>
        <v-col><a-text-field label="City Start" v-model="cityStart" midtext v-caps /></v-col>
        <v-col><a-text-field label="City End" v-model="cityEnd" midtext v-caps /></v-col>
        <v-col><a-text-field label="Version" v-model="version" midtext v-caps /></v-col>
      </v-row>
      <v-row>
        <v-col><a-text-field label="Supplier" v-model="supplier" midtext /></v-col>
        <v-col>
          <elwrapper label="Nb of nights" midtext><f-number v-model="numberOfNights" /></elwrapper
        ></v-col>
        <v-col class="d-flex align-center">
          <input type="checkbox" v-model="isFavorite" />
          <label class="px-2">Favorite</label>
        </v-col>
      </v-row>
      <div class="d-flex mt-2">
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button />
      </div>
    </v-form>
  </a-card>
</template>

<script>
import { ClientAutocomplete } from "@/modules/clients";
export default {
  components: {
    ClientAutocomplete,
  },
  data() {
    return {
      client: null,
      reference: "",
      cityStart: "",
      cityEnd: "",
      version: "",
      year: "",
      supplier: "",
      numberOfNights: "",
      isFavorite: false,
    };
  },
  methods: {
    clearSearch() {
      this.client = null;
      this.reference = "";
      this.cityStart = "";
      this.cityEnd = "";
      this.version = "";
      this.year = "";
      this.supplier = "";
      this.numberOfNights = "";
      this.isFavorite = false;
    },
    updateSearch() {
      const searchData = {
        ...(this.client && { client: this.client }),
        ...(this.reference && { reference: this.reference }),
        ...(this.cityStart && { city_start: this.cityStart }),
        ...(this.cityEnd && { city_end: this.cityEnd }),
        ...(this.version && { version: this.version }),
        ...(this.year && { year: this.year }),
        ...(this.supplier && { supplier: this.supplier }),
        ...(this.numberOfNights && { number_of_nights: this.numberOfNights }),
        ...(this.isFavorite && { is_favorite: this.isFavorite }),
      };
      this.$emit("search", searchData);
    },
  },
};
</script>
