<template>
  <a-card>
    <v-col>
      <v-row><h3>General</h3></v-row>
      <v-row align="center" justify="center" class="px-1 py-1 flex-nowrap">
        <ClientAutocomplete v-model="data.client" />
        <elwrapper label="Reference" smlabel>
          <a-text-field v-model="data.reference" smlabel />
        </elwrapper>
        <a-group class="px-2 py-2" label="City" icon="location_city">
          <elwrapper label="Start" smlabel>
            <v-autocomplete dense outlined hide-details :items="cities" v-model="data.cityStart"></v-autocomplete>
          </elwrapper>
          <elwrapper label="End" smlabel>
            <v-autocomplete dense outlined hide-details :items="cities" v-model="data.cityEnd"></v-autocomplete>
          </elwrapper>
          <elwrapper label="Version" smlabel>
            <a-text-field v-model="data.version" smlabel v-caps />
          </elwrapper>
        </a-group>
      </v-row>
      <v-row align="center" justify="start" class="px-1 py-1 flex-nowrap">
        <QuotePeriod v-model="data.period" :id="data.id" @input="(v) => onQuotePeriodInput(v)" />
        <div class="ml-2">or</div>
        <a-group class="ml-2 px-2 py-2" label="Nb of nights">
          <f-number
            class="ml-2"
            v-model="data.nights"
            :disabled="isNightDisabled()"
            @input="(v) => onNightsInput(v)"
            @focus="(e) => onNightsFocus(e)"
            @blur="(e) => onNightsBlur(e)"
          />
        </a-group>
        <elwrapper class="ml-2" label="Accommodation category" widelabel>
          <f-dropdown multiple :items="accommodationTypes" v-model="data.accommodations" />
        </elwrapper>
      </v-row>
      <v-row align="center" justify="start" class="px-1 py-1 flex-nowrap">
        <elwrapper label="Program's Title" widelabel>
          <a-text-field v-model="data.title" smlabel />
        </elwrapper>
      </v-row>
    </v-col>
  </a-card>
</template>

<script>
import { ClientAutocomplete } from "@/modules/clients";
import { SupplierHotelMeta } from "../../../config/fields/suppliers";
import { QuotePeriod } from "@/modules/quotes";
export default {
  components: {
    ClientAutocomplete,
    QuotePeriod,
  },
  props: {
    value: Object,
    supplierProductData: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    accommodationTypes() {
      return SupplierHotelMeta.hotel_class.source.reverse();
    },
    cities() {
      return Object.values(this.supplierProductData)
        .map((item) => item.supplier.city)
        .filter((city) => city !== null && city !== undefined)
        .map((city) => city.trim().toUpperCase())
        .filter((city, index, array) => array.indexOf(city) === index);
    },
  },
  methods: {
    onQuotePeriodInput(v) {
      const { startYear, startMonth, startDay, endYear, endMonth, endDay } = v;

      if (
        startYear !== null &&
        startMonth !== null &&
        startDay !== null &&
        endYear !== null &&
        endMonth !== null &&
        endDay !== null
      ) {
        const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
        const endDate = new Date(`${endYear}-${endMonth}-${endDay}`);

        const timeDifference = endDate.getTime() - startDate.getTime();
        const nights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        this.data.nights = nights;
        this.tempVariableCosts = this.data.variableCosts;
        this.updateVariableCosts(nights);
      }
    },
    updateVariableCosts(nights) {
      // Update the row of data for variable costs based on the number of nights
      this.data.variableCosts = [];
      if (nights === "") return;

      const days = Number(nights) + 1;
      for (let i = 0; i < days; i++) {
        const existingCost = this.tempVariableCosts[i];
        if (existingCost !== null && existingCost !== undefined) {
          this.data.variableCosts[i] = existingCost;
        } else {
          let unixTimeStamp = null;
          const { startYear, startMonth, startDay, endYear, endMonth, endDay } = this.data.period;
          if (
            startYear !== null &&
            startMonth !== null &&
            startDay !== null &&
            endYear !== null &&
            endMonth !== null &&
            endDay !== null
          ) {
            const tempDate = new Date(`${startYear}-${startMonth}-${startDay}`);
            if (i !== 0) {
              tempDate.setDate(tempDate.getDate() + i);
            }
            unixTimeStamp = tempDate.getTime();
          }
          this.data.variableCosts.push({
            index: i,
            unixTimeStamp,
            items: [],
          });
        }
      }
    },
    // Nights field
    onNightsInput(v) {
      this.updateVariableCosts(v);
    },
    onNightsFocus(e) {
      this.tempVariableCosts = this.data.variableCosts;
    },
    onNightsBlur(e) {
      this.tempVariableCosts = [];
    },
    isNightDisabled() {
      return this.data.period.startDay != null || this.data.period.endDay != null;
    },
  },
};
</script>
